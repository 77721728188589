@tailwind base;
@tailwind components;
@tailwind utilities;

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: rgb(0, 144, 211) !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgb(0, 23, 155) !important;
}

.user-table-tablerow th {
  background-color: #1c53a4;
  color: white;
  border-right: 1px solid white;
}

.offline {
  color: orangered;
  font-size: 14px;
  font-weight: 700;
}
.online {
  color: green;
  font-size: 14px;
  font-weight: 700;
}
.basic_class {
  text-transform: capitalize !important;
  color: #20c1e9;
  font-weight: 900;
}
.prouser {
  content: "";
  color: rgb(126, 211, 115) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.prouser::before {
  content: "";
  background-color: #20c1e9;
  color: rgb(126, 211, 115) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.prouser::after {
  content: "*";

  text-transform: capitalize !important;
  color: rgb(126, 211, 115) !important;
  font-weight: 900;
}

.premiumuser {
  content: "";
  color: rgb(204, 177, 23) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.premiumuser::before {
  content: "";
  background-color: #20c1e9;
  color: rgb(204, 177, 23) !important;
  text-transform: capitalize !important;
  font-weight: 900;
}
.premiumuser::after {
  content: "*";

  text-transform: capitalize !important;
  color: rgb(204, 177, 23) !important;
  font-weight: 900;
}

.stopuser {
  text-transform: capitalize !important;
  color: red;
  text-decoration: line-through;
  font-weight: 700;
}

.Firstcalender {
  width: 280px;
}
.modal-inner-summary .MuiDialog-container .MuiPaper-root {
  max-width: 1000px;
}