.businessInformation {
    margin: 90px 0;
}

.businessInformation .businessGrid {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
}

.table-filter-sec {
    text-align: right;
}

.my-business-content {
    padding-right: 80px;
}

.my-business-content .business-sub-heading {
    font-size: 16px;
    line-height: 29px;
    font-family: var(--font-family-poppins);
    font-weight: 500;
    color: var(--primary-color);
}

.my-business-content .business-heading {
    font-size: 40px;
    line-height: 50px;
    font-family: var(--font-family-poppins);
    font-weight: 700;
    color: var(--black-color);
    margin-bottom: 30px;
}

.my-business-content p {
    font-size: 16px;
    line-height: 26px;
    font-family: var(--font-family-poppins);
    font-weight: 400;
    color: var(--light-gray);
    margin-bottom: 15px;
}

.my-business-img {
    max-width: 480px;
    width: 100%;
    margin-top: 120px;
}

.my-business-img img {
    width: 100%;
    height: 100%;
    border: 1px solid #0D98BA;
    border-radius: 10px;
    overflow: hidden;
}

.business-table .buisness-table-inner .common-btn {
    color: #fff;
    text-decoration: none;
    display: inline-block;
}

.extra-box>div {
    min-height: 0;
}

.main-box .common-btn {
    display: inline-block;
    margin: 15px;
}

.main-box .modal-box {
    text-align: right;
}

.popup-head svg path {
    fill: #000;
}

.step-form {
    padding: 90px 0;
}
.step-form .form-outer {
    padding: 40px 20px;
    box-shadow: 0 3px 15px rgb(0 0 0 / 8%);
    border-radius: 15px;
    background: linear-gradient(-45deg, #0D98BA, #6cad0350, #fff, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
.step-form .form-outer form.b-form input {
    padding: 10px !important;
    min-height: 30px !important;
}

.step-form .form-outer .select-list>div>div {
    padding: 10px;
    height: 30px;
}

.two-list>div>div>div {
    padding: 14px;
    height: 50px;
}

.step-form .form-outer form.b-form .form-inner>div>div {
    margin: 5px 0;
}



.step-form .two-list {
    position: relative !important;
}

.step-form .search-btn {
    position: absolute;
    top: 62%;
    padding: 9px;
    right: 18px;
    transform: translateY(-50%);
}

form.date-box>div {
    margin: 0;
}

.s-relative {
    position: relative;
}

.s-relative .modal-box {
    position: absolute;
    top: 0;
    right:0;
}

.Modal_footer {
    text-align: right;
}

/* .step-form {
    padding: 90px 0;
    background: rgb(107,173,3);
    background: linear-gradient(234deg, rgba(107,173,3,1) 35%, rgba(25,118,210,1) 100%);
} */

.Modal_footer .common-btn {
    background: var(--green-color) !important;
    font-size: 17px;
    line-height: 24px;
    color: var(--white-color);
    font-family: var(--font-family-manrope);
    border-radius: 4px;
    font-weight: 200;
    padding: 13px 32px;
    text-transform: none;
    transition: all 0.3s ease;
}

.Modal_footer .common-btn-outline {
    background-color: #fff;
    border: 1px solid #6BAD03 !important;
    color: #6BAD03 !important;
    font-size: 14px;
    line-height: 24px;
    padding: 13px 32px;
}

.selectOption{
    width: 100%;
    padding: 12px !important;
    border-radius: 5px;
    background-color: white;
    border-color: rgba(0, 0, 0, 0.253);
    min-height: 3.9375em;
}
.selectOption:hover{
    border-color:rgba(0, 0, 0);
}
.dropDownOption{
    padding: 0px 10px !important;
}

/* ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
    height: 350px;
} */

/* media query start */

@media only screen and (max-width: 1360px) {
    .step-form .form-outer .b-form p {
        font-size: 14px;
    }

    .step-form .search-btn {

        top: 61%;
    }
}

@media only screen and (max-width: 1199px) {
    .step-form .two-list {
        width: 100%;
    }
}

@media only screen and (max-width:991px) {
    .businessInformation {
        margin: 60px 0;
    }

    .my-business-content .business-heading {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 15px;
    }

    .my-business-content p {
        font-size: 14px;
    }

    .my-business-content {
        padding-right: 10px;
    }

    .my-business-img {
        margin-top: 0;
    }
}

@media only screen and (max-width:767px) {
    .businessInformation {
        margin: 25px 0;
    }
}

@media only screen and (max-width:575px) {

    .step-bar span.MuiStepLabel-labelContainer {
        display: none;
    }

    .label-step {
        padding-top: 0 !important;
    }
}
